import React from "react";
import { Formik } from "formik";
import { handleLogin } from "../services/auth";

const validate = (values) => {
  const errors = {};

  if (!values.username) {
    errors.username = "Este campo es obligatorio";
  }

  if (!values.password) {
    errors.password = "Este campo es obligatorio";
  }

  return errors;
};

const LoginForm = () => (
  <div
    id="form-Container"
    className="w-full xs:w-9/12 max-w-lg mx-auto md:mx-0"
  >
    <div className="bg-white p-4 flex flex-col w-full shadow-xl rounded-xl">
      <div className="text-center">
        <p className="mt-3 text-gray-500">Iniciar sesión para acceder</p>
      </div>
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validate={validate}
        onSubmit={async (values, actions) => {
          try {
            handleLogin("loginApiPath", values);
          } catch (e) {
            actions.setStatus({
              loginError: "Nombre usuario o contraseña no son correctos",
            });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          status,
          errors,
          handleSubmit,
          handleChange,
          values,
          setStatus,
        }) => (
          <form action="" className="w-full" onSubmit={handleSubmit}>
            <div id="input" className="flex flex-col w-full my-5">
              <input
                type="text"
                id="username"
                placeholder="Usuario"
                className={`appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 ${
                  errors.username || (status && status.loginError)
                    ? "ring-2 ring-red-600"
                    : ""
                } placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-default focus:shadow-lg`}
                onChange={(e) => {
                  setStatus({});
                  return handleChange(e);
                }}
                value={values.username}
              />
              {errors.username ? (
                <div className="text-xs pb-3 text-red-600 text-left pl-2 pt-2">
                  {errors.username}
                </div>
              ) : null}
            </div>

            <div id="input" className="flex flex-col w-full my-5">
              <input
                type="password"
                id="password"
                placeholder="Contraseña"
                className={`appearance-none border-2 border-gray-100 rounded-lg px-4 py-3 ${
                  errors.password || (status && status.loginError)
                    ? "ring-2 ring-red-600"
                    : ""
                } placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-default focus:shadow-lg`}
                autoComplete="on"
                onChange={(e) => {
                  setStatus({});
                  return handleChange(e);
                }}
                value={values.password}
              />
              {errors.password ? (
                <div className="text-xs pb-3 text-red-600 text-left pl-2 pt-2">
                  {errors.password}
                </div>
              ) : null}
            </div>

            <div id="button" className="flex flex-col w-full my-5">
              <button
                type="submit"
                className="w-full py-4 bg-primary-default rounded-lg text-green-100"
              >
                <div className="flex flex-row items-center justify-center">
                  <div className="mr-2">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                      />
                    </svg>
                  </div>
                  <div className="font-bold">Entrar</div>
                </div>
              </button>
              {status && status.loginError ? (
                <div className="text-xs pb-3 text-red-600 text-center pl-2 pt-2">
                  {status.loginError}
                </div>
              ) : null}
              <div className="flex justify-evenly mt-5">
                <a
                  href="#"
                  className="w-full text-center font-medium text-gray-500"
                >
                  ¿Has olvidado tu contraseña?
                </a>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  </div>
);
export default LoginForm;
