import React, { useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link, navigate } from "gatsby";
import { SEO } from "../components/Seo";
import seoData from "../data/seoData.json";
import LoginForm from "../components/LoginForm";
import { isLoggedIn } from "../services/auth";

const login = () => {
  useEffect(() => {
    if (isLoggedIn()) {
      navigate("/clientportal/", { replace: true });
    }
  }, []);

  return (
    <>
      <div className="absolute z-0 w-[100vw] max-w-[100%]">
        <div className="bgfilter" />
        <StaticImage
          src="../images/whypeople_imagen1.jpg"
          objectFit="cover"
          alt="whypeople"
          style={{
            width: "100vw",
            maxWidth: "100%",
            position: "fixed",
            zIndex: "-5",
            minHeight: "100vh",
          }}
        />
      </div>
      <section className="container w-5/6 max-w-7xl px-6 py-6 mx-auto min-h-full gradient-form md:h-screen ">
        <div className="flex flex-col text-center lg:text-left lg:flex-row lg:mr-6 min-h-screen justify-evenly md:items-center relative z-5">
          <div className="flex flex-col-reverse lg:flex-col w-full ">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold text-center leading-tight w-full text-primary-darkest">
              Área Cliente
            </h1>
            <div className="flex flex-col justify-center mt-6 mb-6 lg:pr-10">
              <Link to="/">
                <StaticImage
                  src="../images/logos/logo_personas_resultados.png"
                  placeholder="blurred"
                  height={228}
                  formats={["auto", "webp", "avif"]}
                  alt="WhyPeople. Ir a la portada"
                  transformOptions={{ fit: "cover", cropFocus: "attention" }}
                />
              </Link>
            </div>
          </div>
          <LoginForm />
        </div>
      </section>
    </>
  );
};

export default login;

export const Head = () => (
  <SEO
    title="Accede a tu Área de Cliente | WhyPeople"
    description="¿Listo para hacer crecer tu negocio? Contacta con nuestro equipo experto en Consultoría de Recursos Humanos, organización y dirección."
    keywords={["Acceso a WhyPeoople"]}
  >
    <script type="application/ld+json">{JSON.stringify(seoData)}</script>
  </SEO>
);
